<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0">
    <div class="text-xs-center">
      <v-dialog
        v-model="visibility"
        width="500"
        scrollable
        lazy
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card>
          <v-card-text>
            <v-form class="px-3" ref="membersApplication">
              <v-layout row wrap>
                <!-- Email -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="email"
                    label="Email address"
                    v-model="email"
                    hint="For payment over PayPal"
                    prepend-icon="mdi-at"
                    inputmode="email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-flex>
                <!-- Email -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="email"
                    label="Verify email"
                    hint="Verify your email address"
                    prepend-icon=" "
                    inputmode="email"
                    :rules="[rules.required, rules.match]"
                  ></v-text-field>
                </v-flex>

                <!-- First name -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="given-name"
                    label="First name"
                    hint="As on your ID"
                    v-model="firstName"
                    prepend-icon="mdi-account"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Last name -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="family-name"
                    label="Last name"
                    hint="As on your ID"
                    v-model="lastName"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Nickname -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="nickname"
                    label="Known as (optional)"
                    hint="Name for your MLM card"
                    v-model="nickName"
                    prepend-icon="mdi-account-heart"
                  ></v-text-field>
                </v-flex>

                <!-- New or existing -->
                <v-switch
                  color="primary"
                  label="I'm renewing my existing membership"
                  v-model="existing"
                ></v-switch>

                <!-- Membership number -->
                <v-flex xs12 pb-1 pt-0 v-if="existing">
                  <v-text-field
                    label="Membership Number (if known)"
                    :rules="[rules.number]"
                    prefix="MLM-"
                    type="number"
                    v-model="mlmNumber"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    prepend-icon="mdi-numeric"
                  ></v-text-field>
                </v-flex>

                <!-- Day of birth -->
                <v-flex xs4 px-1>
                  <v-text-field
                    v-model="bday"
                    label="DD"
                    type="number"
                    prepend-icon="mdi-calendar-blank"
                    hint="Day of birth"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    autocomplete="bday-day"
                    :rules="[
                      rules.required,
                      rules.noText,
                      rules.day,
                      rules.adultDay
                    ]"
                  ></v-text-field>
                </v-flex>

                <!-- Month of birth -->
                <v-flex xs4 px-1>
                  <v-text-field
                    v-model="bmonth"
                    label="MM"
                    type="number"
                    hint="Month of birth"
                    autocomplete="bday-month"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    :rules="[
                      rules.required,
                      rules.noText,
                      rules.month,
                      rules.adultMonth
                    ]"
                  ></v-text-field>
                </v-flex>

                <!-- Year of birth -->
                <v-flex xs4 px-1>
                  <v-text-field
                    v-model="byear"
                    label="YYYY"
                    type="number"
                    hint="Year of birth"
                    autocomplete="bday-year"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    :rules="[
                      rules.required,
                      rules.noText,
                      rules.year,
                      rules.adultYear
                    ]"
                  ></v-text-field>
                </v-flex>

                <!-- Street 1 -->
                <v-flex xs12 px-1>
                  <v-text-field
                    single-line
                    autocomplete="address-level1"
                    label="Address 1"
                    v-model="street1"
                    prepend-icon="mdi-home"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>

                <!-- Street 2 -->
                <v-flex xs12 px-1>
                  <v-text-field
                    label="Address 2 (optional)"
                    v-model="street2"
                    prepend-icon=" "
                    single-line
                    small
                  ></v-text-field>
                </v-flex>

                <!-- City -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="address-level2"
                    label="City"
                    v-model="city"
                    prepend-icon=" "
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-flex>

                <!-- Postcode -->
                <v-flex xs6 px-1>
                  <v-text-field
                    autocomplete="postal-code"
                    label="Postcode"
                    v-model="postcode"
                    prepend-icon=" "
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>

                <!-- Country -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="country"
                    label="Country (Optional)"
                    v-model="country"
                    prepend-icon=" "
                  ></v-text-field>
                </v-flex>

                <!-- Phone number -->
                <v-flex xs12 px-1>
                  <v-text-field
                    autocomplete="tel-national"
                    label="Phone number (optional)"
                    v-model="phoneNumber"
                    prepend-icon="mdi-phone"
                    inputmode="tel"
                    :rules="[rules.phone]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap>
              <v-flex xs-6 px-4 pb-3>
                <v-btn flat @click="signupClosed">Cancel</v-btn>
              </v-flex>
              <v-flex xs-6 class="text-xs-right px-4 pb-2">
                <v-btn
                  class="primary grey--text text--darken-4"
                  @click="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
import axios from "axios";
export default {
  props: ["signup"],
  data() {
    return {
      // Set how date-picker is displayed
      date: null,
      menu: false,
      today: null,

      // Pop-up dialog control

      // Form fields
      email: "",
      firstName: "",
      lastName: "",
      nickName: "",
      existing: "",
      mlmNumber: "",
      bday: null,
      bmonth: null,
      byear: null,
      street1: "",
      street2: "",
      city: "",
      postcode: "",
      country: "",
      phoneNumber: "",

      // Password controls
      snackbar: false,

      // Form interaction
      loading: false,

      // Form validation
      rules: {
        required: value => {
          if (value !== "" || value !== null) {
            return true;
          } else {
            ("Required field");
          }
        },
        noText: value => {
          const regex = /^[0-9]*$/;
          if (regex.test(value) === true || value === "") {
            return true;
          } else {
            return "Numbers only";
          }
        },
        phone: value => {
          const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;
          if (regex.test(value) === true || value == "" || value == null) {
            return true;
          } else {
            return "Please enter a valid number like +447755123555 or 07755123555.";
          }
        },
        match: value => (value === this.email || 'The emails don\'t match.'),
        day: value => {
          const regex = /^([0-9]{1,2})$/;
          if (value > 0 && value < 32 && regex.test(value) !== false) {
            return true;
          } else {
            return "1 or 2 digit format";
          }
        },
        month: value => {
          const regex = /^([0-9]{1,2})$/;
          if (value > 0 && value < 13 && regex.test(value) !== false) {
            return true;
          } else {
            return "1 or 2 digit format";
          }
        },
        year: value => {
          const regex = /^([0-9]{4})$/;
          if (value > 1899 && regex.test(value) !== false) {
            return true;
          } else {
            return "4 digit format";
          }
        },
        adultDay: value => {
          let maxYear = this.maxYear;
          if (
            this.byear == maxYear &&
            this.bmonth == this.month &&
            value > this.day
          ) {
            return "18+ only";
          } else {
            return true;
          }
        },
        adultMonth: value => {
          let maxYear = this.maxYear;
          if (this.byear == maxYear && value > this.month) {
            return "18+ only";
          } else {
            return true;
          }
        },
        adultYear: value => {
          let maxYear = this.maxYear + 1;
          if (value < maxYear) {
            return true;
          } else {
            return "18+ only";
          }
        },
        number: value => {
          if (value == "") {
            return true;
          } else {
            const regex = /^([0-9]{4})$/;
            return regex.test(value) || "Please enter a 4 digits number.";
          }
        },
        email: value => {
          if (value == undefined) {
            return false;
          } else {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Please enter a valid email address.";
          }
        },
        name: value => {
          if (value == undefined) {
            return false;
          } else {
            const regex = /^[a-zA-Z\- ']+$/;
            return (
              regex.test(value) || "English letters, spaces and dashes only."
            );
          }
        }
      }
    };
  },

  computed: {
    // Today's date
    actualDate() {
      return new Date();
    },
    diagnosticData() {
      var nAgt = navigator.userAgent;
      var browserName = navigator.appName;
      var fullVersion = "" + parseFloat(navigator.appVersion);
      var nameOffset, verOffset, ix;
      var width=screen.width;
      var height=screen.height;

      // In Opera, the true version is after "Opera" or after "Version"
      if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
          fullVersion = nAgt.substring(verOffset + 8);
      }
      // In MSIE, the true version is after "MSIE" in userAgent
      else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset + 5);
      }
      // In Chrome, the true version is after "Chrome"
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset + 7);
      }
      // In Safari, the true version is after "Safari" or after "Version"
      else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1)
          fullVersion = nAgt.substring(verOffset + 8);
      }
      // In Firefox, the true version is after "Firefox"
      else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset + 8);
      }
      // In most other browsers, "name/version" is at the end of userAgent
      else if (
        (nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))
      ) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }
      // trim the fullVersion string at semicolon/space if present
      if ((ix = fullVersion.indexOf(";")) != -1)
        fullVersion = fullVersion.substring(0, ix);
      if ((ix = fullVersion.indexOf(" ")) != -1)
        fullVersion = fullVersion.substring(0, ix);
      
      let diag = {};
      diag.browser = browserName;
      diag.ver = fullVersion;
      diag.app = navigator.appName;
      diag.agent = navigator.userAgent;
      diag.res = width + " x " + height;
      return diag;      
    },
    // Birthyear of 18+
    maxYear() {
      let year = this.actualDate.getFullYear() - 18;
      return year;
    },
    // today's month
    month() {
      let month = this.actualDate.getMonth() + 1;
      return month;
    },
    day() {
      let day = this.actualDate.getDate();
      return day;
    },
    // ENV variables
    envVariables() {
      if (this.$env === "development") {
        var development = {};
        development.backend = "http://synology.ertech.cz:8755/registration";
        return development;
      } else {
        var production = {};
        production.backend = "https://mlm-nodemailer.appspot.com/registration";
        return production;
      }
    },

    // Open registration form
    visibility: {
      get: function() {
        return this.$store.getters.memDialog;
      },
      set: function(newValue) {
        if (!newValue) {
          this.$store.dispatch("deactivateMemDialog");
          this.$refs.membersApplication.reset();
        }
      }
    }
  },

  metaInfo: {
    titleTemplate: "%s | Join us"
  },

  // Start date pciker with year
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },

  methods: {
    // Submit form
    submit() {
      if (this.$refs.membersApplication.validate()) {
        if (this.street2 === undefined) {
          this.street2 = "";
        }
        if (this.phoneNumber === undefined) {
          this.phoneNumber = "";
        }
        this.loading = true;        
        const memberForm = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          nickName: this.nickName,
          existing: this.existing,
          mlmNumber: this.mlmNumber,
          bday: this.bday.toString(),
          bmonth: this.bmonth.toString(),
          byear: this.byear.toString(),
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          country: this.country,
          postcode: this.postcode,
          phoneNumber: this.phoneNumber,
          browser: this.diagnosticData.browser,
          ver: this.diagnosticData.ver,
          app: this.diagnosticData.app,
          agent: this.diagnosticData.agent,
          res: this.diagnosticData.res
        };

        axios
          .post(this.envVariables.backend, {
            headers: {
              "Access-Control-Allow-Origin": "allow",
              "Content-type": "application/x-www-form-urlencoded"
            },
            data: {
              user: memberForm
            }
          })
          .then(() => {
            this.loading = false;
            this.$store.dispatch("deactivateMemDialog");
            this.$store.dispatch("activateNewUserSnackbar");
          })
          .catch(error => {
            this.$store.dispatch(
              "activateRegistrationErrorSnackbar",
              "Oops, something went wrong (" + error + ")."
            );
            this.loading = false;
          });
        /*    // sign up the user
        auth
          // pass username and password to Firebase
          .createUserWithEmailAndPassword(email, password)

          // Pass registration details
          .then(cred => {
            db.collection("users")
              .doc(cred.user.uid)
              .set({
                firstName: memberForm.firstName,
                lastName: memberForm.lastName,
                birthDate: memberForm.birthDate,
                street1: memberForm.street1,
                street2: memberForm.street2,
                postcode: memberForm.postcode,
                phoneNumber: memberForm.phoneNumber,
                city: memberForm.city,
                primaryTheme: '#BBDEFB',
              });
          })

          // Send verification email
          .then(() => {
            var user = auth.currentUser;
            user.sendEmailVerification();
          })

          .then(() => {
            var user = auth.currentUser;
            user.updateProfile({
              displayName: memberForm.nickName
            });
          })

          .then(() => {
            this.loading = false;
            this.$store.dispatch("deactivateMemDialog");
            this.$store.dispatch("activateNewUserSnackbar");
          })
          .catch(error => {
            this.$store.dispatch(
              "activateRegistrationErrorSnackbar",
              error.message
            );
            this.loading = false;
          }); */
      }
    },

    // Close the form by clicking cancel
    signupClosed() {
      this.$store.dispatch("deactivateMemDialog");
      this.$refs.membersApplication.reset();
    },

    // How date picker saves the value
    save(date) {
      this.$refs.menu.save(date);
    }
  }
};
</script>
