<template>
  <v-layout row pb-4 wrap class="mt-0 pt-0 pb-4 mb-4">
    <v-flex xs12 mb-4 pb-4 mt-0>
      <v-card max-width="600" class="mx-auto mb-4" v-show="credentials">
        <!-- should be !credentials -->
        <v-card-text class="subheading text-xs-center grey--text pb-0 mb-0">
          To purchase the membership please log in or create a free account
          first.
          <v-btn color="success" @click="submitForm">text</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-container grid-list-xs class="pb-1 pt-0">
            <v-layout row wrap class="mr-4">
              <v-flex xs6 class="pr-2">
                <v-btn
                  color="primary"
                  class="primary grey--text text--darken-4 ma-3"
                  @click="$store.dispatch('activateLoginDialog')"
                  block
                >
                  <v-icon left>mdi-login</v-icon>
                  Log in</v-btn
                >
              </v-flex>
              <v-flex xs6 class="pl-3">
                <v-btn
                  color="primary"
                  class="primary grey--text text--darken-4 ma-3"
                  @click="$store.dispatch('activateRegDialog')"
                  block
                >
                  <v-icon left>mdi-account-circle</v-icon>
                  Register</v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
      <v-hover>
        <v-card
          slot-scope="{ hover }"
          class="mx-auto"
          max-width="600"
          pb-4
          mb-4
        >
          <v-img :aspect-ratio="16 / 9" src="/assets/become-a-member.jpg">
            <v-card-title class="headline event-title justify-center py-1">
              Only £20.00 a year
            </v-card-title>
            <v-expand-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out black v-card--reveal display-3 white--text"
                style="height: 100%;"
              >
                £20.00 a year
              </div>
            </v-expand-transition>
          </v-img>
          <v-card-text class="my-2" style="position: relative; top:-15px;">
            <v-btn
              v-show="credentials"
              absolute
              color="primary"
              class="black--text"
              fab
              right
              top
            >
              <v-icon>mdi-paypal</v-icon>
            </v-btn>
            <h3 class="display-1 font-weight-light primary--text mb-2">
              <strong></strong>
            </h3>
            <div class="mt-4 text-xs-center text-sm-left grey--text">
              Please complete the form to become a member of Manchester
              Leathermen. Membership is open to anyone over the age of 18 and
              who identifies as male.
            </div>
            <div class="mt-4 text-xs-center text-sm-left grey--text">
              Membership is currently only £20 per year and you will receive
              your membership pack within one week. Your pack will contain full
              details of the exclusive benefits available to you as member of
              Manchester Leathermen.
            </div>
            <div class="mt-4 text-xs-center text-sm-left grey--text">
              If you have any questions, please email our Membership Officer.
              You should get an email confirmation on the form submission.
              Please get in touch if you don't get an email within a few
              minutes.
            </div>
          </v-card-text>
          <v-divider v-show="!credentials"></v-divider>
          <!-- should be credentials -->
          <v-card-actions v-show="!credentials">
            <!-- should be credentials -->
            <v-btn
              my-1
              color="primary"
              class="primary grey--text text--darken-4 ma-3"
              block
              @click="membershipNeeded"
              ><v-icon left>mdi-account-plus</v-icon> Become a Member for
              £20.00</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-flex>
    <membership-form />
  </v-layout>
</template>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>

<script>
import MembershipForm from "@/components/MembershipForm";
export default {
  components: { MembershipForm },
  data() {
    return {
      // Pop-up dialog control
      snackbar: false,
      openSignup: false,
    };
  },
  computed: {
    credentials: function() {
      return this.$store.getters.credentials;
    },
  },
  methods: {
    // Temp dialog for membership
    membershipNeeded() {
      this.$store.dispatch("activateMemDialog");
    },
  },
  metaInfo: {
    titleTemplate: "%s | Join us",
  },
};
</script>
